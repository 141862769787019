<template>
  <div>
    <left-and-right>
      <div slot="leftDiv">
        <div class="content">
          <h3>{{ data.title }}</h3>
          <h5>{{ new Date(data.time).toLocaleString() }}</h5>

          <div style="cursor: default" v-html="data.content"></div>

        </div>
        <last-and-next v-on:toArticle="toArticle" :last="lastArticle" :next="nextArticle"></last-and-next>
      </div>
      <div slot="rightDiv">
        <card ref="card">
          <div slot="heard" class="search">
            <input placeholder="搜索">
            <i><img src="../../assets/image/icon/search.png"></i>
          </div>
        </card>
        <card ref="card0"></card>
        <img src="../../assets/image/web.png">
      </div>
    </left-and-right>
  </div>
</template>

<script>
import leftAndRight from "@/components/public/leftAndRight";
import card from "@/components/public/card";
import lastAndNext from "@/components/public/lastAndNext";

export default {
  name: "businessDetails",
  components: {
    leftAndRight,
    card, lastAndNext
  },
  data() {
    return {
      id: this.$route.query.id,
      data: {},
      lastArticle: {
        name: '',
        id: null
      },
      nextArticle: {
        name: '',
        id: null
      }
    }
  },
  mounted() {
    this.getArticle()
  },
  methods: {
    getArticle() {
      this.$http.get('/safe/getArticle', {
        params: {
          id: this.id
        }
      }).then((res) => {
        this.data = res.data
        this.data.time = this.$constContent.dateFormat("YYYY-mm-dd HH:MM", new Date(res.data.time))
        if (res.data.lastArticle !== null) {
          this.lastArticle.name = res.data.lastArticle.title
          this.lastArticle.id = res.data.lastArticle.id
        } else {
          this.lastArticle.name = null
        }
        if (res.data.nextArticle !== null) {
          this.nextArticle.name = res.data.nextArticle.title
          this.nextArticle.id = res.data.nextArticle.id
        } else {
          this.nextArticle.name = null
        }

        console.log(this.data)
      })


      const list = ["重要通知", "协会大事记"]

      for (let index in list) {
        const ths = this
        this.$http.get('/safe/getIndexInformation', {
          params: {
            name: list[index],
          }
        }).then((res) => {
          let forms = res.data

          for (let i in forms) {
            let pic = forms[i].pic
            if (pic != null) {
              forms[i].pic = this.$constContent.imgUrl + pic
            }
            let time = forms[i].time;
            let date = new Date(time);
            res.data[i].time = this.$constContent.dateFormat("YYYY-mm-dd HH:MM", date)
          }
          let Cards = []
          let Card = {}
          Card.title = list[index]
          Card.id = 0
          Card.content = res.data
          Cards.push(Card)
          ths.$nextTick(() => {
            if (index == 1) {
              ths.$refs.card.init(Cards)
            } else {
              ths.$refs.card0.init(Cards)
            }
          })
        })
      }
    },
    toArticle(e) {
      this.id = e
      this.getArticle()
    }
  }
}
</script>

<style scoped>
.content {
  width: 100%;
}

.content h3 {
  width: 100%;
  text-align: center;
}

.content h5 {
  width: 100%;
  color: #999999;
  text-align: center;
}

.search {
  width: 100%;
  height: 40px;
  position: relative;
}

.search input {
  height: 38px;
  width: calc(100% - 20px);
  border-radius: 4px;
  padding: 0 10px;
  border: 1px solid #dfdfdf;
}

.search i img {
  width: 15px;
  height: 15px;
  position: absolute;
  top: 12px;
  right: 12px;
}

img {
  width: 100%;
}
</style>
